<template>
  <div>
    <div class="head_section">
      <h2 id="dkBody" class="tit_subdesc">
        <span class="txt_number">요청번호 : {{ docNo }}</span>
        <span class="txt_info">{{ _.unescape(itemData.docTitle) }}</span>
      </h2>
    </div>
    <!-- 결재현황 -->
    <div class="section_g">
      <PayStatus
        :isApprover="false"
        :apprType="apprType"
        :can-approve-edit="canAproveEdit"
        :doc-no="docNo"
        :doc-flag="docFlag"
        :approval-line="approvalLine"
        :add-approver-list="addApproverList"
        :viewer-list="viewerList"
        @searchOrganization="searchOrganization"
        @popDeleteapprover="popDeleteapprover"
      />
    </div>
    <!-- 승인 반려 코멘트 -->
    <RejectComment v-if="rejectYn" :approval-line="approvalLine" />
    <!-- 구매요청 기본정보 -->
    <DraftReqeustInfo :draftData="itemData" />
    <!-- 구매요청품목 -->
    <!-- <DraftPurchaseGoodsList v-if="model.assetType === assetType.INFRA_ASSET" :draftData="model" /> -->
    <!-- 정산정보 -->
    <!-- <DraftPurchasePayment :draftData="model" /> -->
    <!-- PdfViewer -->
    <PdfViewer v-if="itemData.targetPk" :pdfInfoObj="itemData.pdfHistoryResult" />
    <!-- 첨부파일 -->
    <DraftFile :attachFileList.sync="model.attachFileList" />
    <div class="wrap_btn type_bottom">
      <div class="pos_left">
        <button type="button" class="btn_large btn_fourthly" @click.prevent="gotoList">목록</button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from "vuex";
import PayStatus from "@/_approval/components/shared/view/PayStatus";
import RejectComment from "@/_approval/components/shared/view/RejectComment";
import DraftReqeustInfo from "@/_approval/components/payment/view/DraftReqeustInfo";
import DraftPurchaseGoodsList from "@/_approval/components/payment/view/DraftPurchaseGoodsList";
import DraftPurchasePayment from "@/_approval/components/payment/view/DraftPurchasePayment";
import DraftFile from "@/_approval/components/payment/view/DraftFile";
import MyComment from "@/_approval/components/shared/view/MyComment";
import PurchaseRequestDetailMixin from "@/_approval/components/shared/view/PurchaseRequestDetailMixin";
import DraftWriteModel from "@/_approval/pages/payment/write/DraftWriteModel";
import commDocs from "@/_approval/mixins/common/commDocs";
import {
  assetType,
  approvalStatus,
  aprvlLineType,
  docStatus,
  targetGubun,
} from "@/_approval/const/const";
import ApiService from "@/services/ApiService";

import LocalStorageManager from "@/LocalStorageManager";
import PdfViewer from "@/components/shared/PdfViewer";

export default {
  components: {
    PayStatus,
    RejectComment,
    DraftReqeustInfo,
    DraftPurchaseGoodsList,
    DraftPurchasePayment,
    DraftFile,
    MyComment,
    PdfViewer,
  },
  mixins: [PurchaseRequestDetailMixin, commDocs],
  data() {
    return {
      apprType: "",
      apprReason: "",
      nowApprovalInfo: {
        accountId: "",
      },
      docNo: this.$route.params.id,
      itemData: {},
      itemId: "",
      targetPk: "",
      targetGubun: "",
      docStatus: Object.freeze(docStatus),
      partReject: false, // 부분반려 true/false
      model: new DraftWriteModel(),
      approvalLine: [],
      canAproveEdit: false,
      assetType: assetType,
      docFlag: "AR",
    };
  },
  created() {
    this.getData();
    this.updateRead();
  },
  // async mounted() {
  //   const data = await this.fetchStoredDraftData(this.docNo);

  //   // this.model.initFromFetchData(data);
  //   this.model.deptPathName = await this.fetchDeptPathNameByDeptCode(data.drftDeptCode);
  //   this.approvalLine = data.aprvlLineList;

  //   await this.updateRead();
  // },
  methods: {
    async getData() {
      this.itemId = this.$route.params.id;
      const path = `${this.$apiPath.APRVL_DOC_LIST}/STD/${this.itemId}`;

      const result = await ApiService.shared.getData(path);
      const { code, data, text } = result;

      this.itemData = data;
      this.docNo = data.docNo;
      this.targetGubun = data.targetGubun;
      this.approvalLine = data.aprvlLineList;
      this.model = data;
      return;
    },

    async updateRead() {
      const { docStatus, aprvlStatus } = this.docFlagToCode(this.docFlag);

      const params = {
        targetGubun: targetGubun.STANDARD,
        docNoList: [this.docNo],
        readYn: true,
        docStatus,
        aprvlStatus,
      };
      const res = await ApiService.shared.put(this.$apiPath.APRVL_DOC_READ, params);
    },
    gotoList() {
      this.$router.push(this.$paymentRouterPath.PAYMENT_APPROVAL_REJECT);
    },
  },
};
</script>
